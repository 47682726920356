import React from 'react';
//import AnchorLink from 'react-anchor-link-smooth-scroll';
//import LogoIcon from '../../svg/LogoIcon';
//import Button from '../Button';
//import AnchorButton from '../AnchorButton';
import logo from "../../assets/images/logo.png"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faClock } from '@fortawesome/free-solid-svg-icons'
// import { faCogs } from '@fortawesome/free-solid-svg-icons'
// import { faCoins } from '@fortawesome/free-solid-svg-icons'
// import { faComments } from '@fortawesome/free-solid-svg-icons'
// import { faUsers } from '@fortawesome/free-solid-svg-icons'

const Header = () => (
  <header className="sticky top-0 bg-white">
    <nav className="bg-white shadow-lg">
      <div className="container flex flex-row sm:flex-row justify-between items-center mx-auto lg:py-3 lg:px-8">
        <div className="relative flex py-3 items-center text-2xl">
          <div className="absolute inset-0 lg:bg-gradient-to-r lg:from-blue-200 lg:to-blue-100 lg:shadow-lg lg:p-6 lg:transform lg:-skew-x-24 lg:-rotate-6 lg:rounded-3xl"></div>
          <div className="relative px-2 lg:py-2 bg-white lg:shadow-lg rounded-3xl">
            <a title="Back to the Home page" href="/"><img src={logo} alt="Logo" /></a>
          </div>
        </div>
        <div className="flex lg:mt-4 sm:mt-0">
          <a className="lg:px-4" href="/">
            Back to <strong>M</strong>ain page
          </a>
        </div>
      </div>
    </nav>
  </header>
);

export default Header;
