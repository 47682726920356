import React from 'react';
import LayoutSecondary from '../components/layout/LayoutSecondary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AnchorLink from 'react-anchor-link-smooth-scroll';
import backendToolAll from '../assets/images/backend-tool-all.jpg'
import backendConfigureTable from '../assets/images/backend-configure-table.png'
import backendConfigureTableView from '../assets/images/backend-configure-table-view.png'
import backendFilters from '../assets/images/backend-filters.png'
import backendFilterDate from '../assets/images/backend-filter-date.png'
import backendCreateUser from '../assets/images/backend-create-user.png'
import backendDeleteUser from '../assets/images/backend-delete-user.png'
import backendConfigureView from '../assets/images/backend-configure-view.png'
import backendBlockedUser from '../assets/images/backend-blocked-user.png'
import backendExport from '../assets/images/backend-export.png'
import backendNewClient from '../assets/images/backend-new-client.png'
import backendNewProject from '../assets/images/backend-new-project.png'
import {fas} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(fas);

const Success = () => (
  <LayoutSecondary>
    <section className="pt-20 md:py-15">
      <div className="container mx-auto px-8">
        <h1 className="blocktext-4xl md:text-2xl xl:text-3xl font-bold leading-none w-full">
          Backend application documentation
        </h1>
        <h2 className="md:text-xl xl:text-2xl leading-none w-full py-5 md:pt-10 px-2">
          Table of contents
        </h2>
        <ol className="list-decimal px-8">
          {
            [
              {
                name:'General info',
                icon: 'cog',
                anchor: 'general'
              },
              {
                name:'Manage users',
                icon: 'user',
                anchor: 'users'
              },
              {
                name:'Timesheets management',
                icon: 'clock',
                anchor: 'timesheet'
              },
              {
                name:'Reporting',
                icon: 'chart-line',
                anchor: 'reports'
              },
              {
                name:'Extract data to spreadsheet',
                icon: 'file-excel',
                anchor: 'export'
              },
              {
                name:'Manage projects and clients',
                icon: 'users-cog',
                anchor: 'projects'
              },
            ].map(link => (
              <li key={link.name}>
                <AnchorLink className="whitespace-nowrap text-blue-700 hover:text-blue-200 tracking-normal" href={`#${link.anchor}`} title={`Link to ${link.name}`}>
                  <FontAwesomeIcon icon={link.icon} className="mx-2" />
                  {link.name}
                </AnchorLink>
              </li>
            ))
          }
        </ol>

        <div className="text-xl py-5 md:py-10">
          <p className="my-8 font-light">This section will explain how to use <strong>Punch-in backend </strong> application.
            If you are here, most likely you have been tasked with the administration of the company's time management procedures.
            Punch-in backend is the place to accomplish the following tasks:
          </p>
          <ul className="pl-10">
            <li><FontAwesomeIcon icon={'hand-point-right'} className="text-blue-700 pr-2 text-xl" />Keep the list of your users (employees and/or contractors) up to date
              (i.e. remove old records, modify existing ones and create new records for newcomers).
            </li>
            <li><FontAwesomeIcon icon={'hand-point-right'} className="text-blue-700 pr-2 text-xl" />Manage the time your employees have logged on each project (approve the hours, correct errors, etc).</li>
            <li><FontAwesomeIcon icon={'hand-point-right'} className="text-blue-700 pr-2 text-xl" />Visualize reports</li>
            <li><FontAwesomeIcon icon={'hand-point-right'} className="text-blue-700 pr-2 text-xl" />Extract data via the export tool</li>
            <li><FontAwesomeIcon icon={'hand-point-right'} className="text-blue-700 pr-2 text-xl" />Keep the list of projects and clients up to date</li>
          </ul>
          <p className="my-8 font-light">
            Each of the above will be explained in the following paragraphs.
          </p>

          <h2 id="general" className="md:text-xl xl:text-2xl leading-none w-full font-extrabold text-blue-700 pt-15 md:pt-20 px-2">
            General information
          </h2>
          <p className="my-8 font-light">
            This paragraph will explain how the user interface works and how can you personalise it to best suit your needs.
            We will explain how to filter tables, modify table layout, as well as modify the number of lines that the tool will show per page.
          </p>
          <h3 className="md:text-lg xl:text-xl leading-none w-full pt-5 md:pt-10 px-2">
            Filters
          </h3>
          <p className="my-8 font-light">
            Filters can be accessed from any table. Click on the "Filters" button and you will be presented with 3 input selectors per filter.
             The first one selects the table filter you wish to search for (example: creation date greater than 01/01/2022). The second selectors defines
             the type of search (whcih varies depending on the field type); this can be:<br />
           <strong>is</strong> for an identity search (example: lastName is Smith will only search exact matches)<br />
           <strong>is not</strong> for an identity search that does not match (example: lastName is not Smith will only return last names different from Smith)<br />
           <strong>is lower than</strong> works best for dates and numbers, searches for values lower than (example show users with a daily rate less than 40)<br />
           <strong>is lower than or equal to</strong> same as above, but will return also values that are equal to the given parameter (show users created on the 01/01/2022 or before)<br />
           <strong>is greater than</strong> works best for dates and numbers, searches for values higher than (example show users with a daily rate higher than 40)<br />
           <strong>is greater than or equal to</strong> same as above, but will return also values that are equal to the given parameter (show users created on the 01/01/2022 or after)<br />
           <strong>contains (case sensitive)</strong> will try to match the string anywhere in the field (example: description contains "work" will
             return all lines that have the word work in their description, but not Work or WORK)<br />
           <strong>contains</strong> same as above, but it will return all lines that have the word work in their description, including Work or WORK<br />
          </p>
          <div className="m-auto bg-gradient-to-r from-gray-500 to-blue-400">
            <img src={backendFilters}  alt="back-end filters" className="md:p-10" />
          </div>
          <p className="my-8 font-light">
            Depending on the type of field, not all filter search types will be available; for example, a boolean field, i.e. a field that can be only true or false,
            the only allowed search will be is or is not, whilst the search value will be true or false. Likewise, a date field
            will open a calendar search to simplify entering the date value:
          </p>
          <div className="m-auto bg-gradient-to-r from-gray-500 to-blue-400">
            <img src={backendFilterDate}  alt="back-end filter for date" className="md:p-10" />
          </div>
          <h3 className="md:text-lg xl:text-xl leading-none w-full pt-5 md:pt-10 px-2">
            Manage table layout
          </h3>
          <p className="my-8 font-light">
            You can customise the way your table layout looks like by adding or removing fields.
            This is quick and intuitive and available on every table by clicking the cog on the right-hand side of the table top:
          </p>
          <div className="m-auto bg-gradient-to-r from-gray-500 to-blue-400">
            <img src={backendConfigureTable}  alt="back-end configure table" className="md:p-10" />
          </div>
          <p className="my-8 font-light">
            You can add or remove fields just by checking or unchecking the checkbox as show in the picture.
          </p>
          <p className="my-8 font-light">
            You can also customise further by clicking the <strong>"Configure the view"</strong> button. You can:<br />
            - modify the order of appearance of the fields by changing them in the lower part of the screen, where it says <strong>VIEW</strong> (see picture below)<br />
            - choose the default number of entries for that table<br />
          - pick the sorting key (used to put the records in a certain order, for example alphabetical); <strong>Default sort attribute </strong>
          allows to pick the field, then you can further choose whether it should be descending (<strong>DESC</strong>) or ascending (<strong>ASC</strong>)<br />
        - Enable search, bulk actions, filters (by default all set to true): you can restrict searches, filtering and bulk action (i.e. delete multiple rows for example)
          </p>
          <div className="m-auto bg-gradient-to-r from-gray-500 to-blue-400">
            <img src={backendConfigureTableView}  alt="back-end configure table view" className="md:p-10" />
          </div>


          <h2 id="users" className="md:text-xl xl:text-2xl leading-none w-full font-extrabold text-blue-700 pt-15 md:pt-20 px-2">
            Manage users
          </h2>
          <p className="my-8 font-light">
            One of the main activities that needs to be carried out in Punch-in is managing users (employees and/or contractors) profiles.
            You will need to keep your list of users up-to-date with the changes in your organisation, which consists mainly of the following activities:
            remove or disable people that have left, amend a user's profile to reflect changes, add a new user.
          </p>
          <p className="my-8 font-light">
            You can manage users from the users menu as shown below:
          </p>
          <div className="m-auto bg-gradient-to-r from-gray-500 to-blue-400">
            <img src={backendToolAll}  alt="back-end tools" className="md:p-10" />
          </div>
          <h3 className="md:text-lg xl:text-xl leading-none w-full pt-5 md:pt-10 px-2">
            Create new user
          </h3>
          <p className="my-8 font-light">
            To create a new user, click on the &quot;Add new user&quot;
          </p>
          <div className="m-auto bg-gradient-to-r from-gray-500 to-blue-400">
            <img src={backendCreateUser} alt="how to create a user on the back-end interface"  className="p-10"/>
          </div>
          <p className="my-8 font-light">
            You can fill in as much information as you want, depending on your business needs. It makes sense though to include a user's personal details,
            like firs name and surname, email address.
          </p>
          <p className="my-8 font-light">
            <strong>Employee id</strong>: This field can be used to uniquely identify and reconcile your user against your HR system<br/>
            <strong>Username</strong>: This field can be used by the employee to log onto the tool (not yet in use at the moment)<br/>
            <strong>Rate</strong>: This field is used to store the person's hourly rate. It is used to work out how much are you paying the user per hour<br/>
            <strong>External Rate</strong>: How much you are billing your customers for the employee's hours<br/>
            <strong>Confirmed</strong>: This is meant to be used when external account creation is allowed,
              which the tool does not support at the moment<br/>
            <strong>Picture</strong>: This is the tool from which you will be able to select a user's picture. Upload it from here. <strong>Note: </strong>
            It is very important to have a well taken picture, high resolution, passport-like picture, without glasses, hats,
            scarf or other objects hiding the face and hair; there should not be any glows of light, too dark or too bright.<br/>
          </p>
          <p className="my-8 font-light">
            On the right-hand side there are additional options that you may find useful:<br />
          <strong>Information</strong>: it shows who and when was the record last updated<br/>
          <strong>Role</strong>: the tool allows to define multiple roles with different levels of access.
            By default users should have the authenticated role to allow future enhancements<br/>
          <strong>Projects</strong>: from this option you can add projects to a user, allowing them to book hours against it. A user can have multiple projects.<br/>
          </p>

          <h3 className="md:text-lg xl:text-xl leading-none w-full pt-5 md:pt-10 px-2">
            Modify existing user
          </h3>
          <p className="my-8 font-light">
            To modify a new user's details, firstly click on the user from the users list table (left-hand menu / users).
            To find the user's details may want to use the filter facility:
          </p>
          <div className="m-auto bg-gradient-to-r from-gray-500 to-blue-400">
            <img src={backendFilters} alt="Filters on the back end application" className="md:p-10" />
          </div>
          <p className="my-8 font-light">
            The left most field represents the field that you want to search, the middle field the type of search,
            and the last field will contain the string that you wish to look for. Depending on the type of field, different search types will take place.
             Example: if you want to know all users that have been created before 01/01/2022, you'd select created_at - is lower than - select from calendar widget 01/01/2022
          </p>
          <p className="my-8 font-light">
            Once the right user has been selected, by clicking on it, all details appear on screen and you can update any property.
            When you are finished, just click the SAVE button.
          </p>
          <h3 className="md:text-lg xl:text-xl leading-none w-full pt-5 md:pt-10 px-2">
            Delete or disable user
          </h3>
          <p className="my-8 font-light">
            If you wish to completely delete a user, just select it and click on <strong>delete selected</strong>:
          </p>
          <div className="m-auto bg-gradient-to-r from-gray-500 to-blue-400">
            <img src={backendDeleteUser}  alt="how to delete a user on the back-end application"  className="md:p-10" />
          </div>
          <p className="my-8 font-light">
            You may also delete the user by clicking on it so that the details open up and then click on the <strong>"delete this entry" </strong>
            link on the bottom right.
          </p>
          <p className="my-8 font-light">
            Sometimes you may just wish to temporarily remove the user from the system. If this is the case, just select the user and check the blocked radio button:

          </p>
          <div className="m-auto bg-gradient-to-r from-gray-500 to-blue-400">
            <img src={backendBlockedUser}  alt="back-end blocked users" className="md:p-10" />
          </div>

          <h2 id="timesheet" className="md:text-xl xl:text-2xl leading-none w-full font-extrabold text-blue-700 pt-15 md:pt-20 px-2">
            Manage time sheets
          </h2>
          <p className="my-8 font-light">
            You can access time sheets from the left-hand menu Time-sheets. As for the users table, you can filter out results using the same principles.
            This is especially useful when you need to only select a certain time frame. You can also add more filters which act as a logical AND.
            So, for example, you can filter all time sheets from a certain date AND a certain other date (From is greater than... AND Until is lower than... AND project is...)
          </p>
          <p className="my-8 font-light">
            Normally there should not be a lot to do with time sheets except approve them or reject them. In rare cases you may need to delete or amend them.
            You may delete them by selecting them from the list and then click on the <strong>"delete selected"</strong> link.
            You may also delete the entry by clicking on it so that the details open  and then click on the <strong>"delete this entry" </strong>
            link on the bottom right.
          </p>
          <p className="my-8 font-light">
            A useful feature is the table details layout configuration, which can be modified to include/exclude any field from the table representation:
          </p>

          <div className="m-auto bg-gradient-to-r from-gray-500 to-blue-400">
            <img src={backendConfigureView} alt="back-end configure view"  className="md:p-10" />
          </div>

          <h2 id="reports" className="md:text-xl xl:text-2xl leading-none w-full font-extrabold text-blue-700 pt-15 md:pt-20 px-2">
            Reporting
          </h2>
          <p className="my-8 font-light">
            Punch-in is delivered with the following reports out of the box:
          </p>
          <ul className="list-disc pl-10 text-base">
            <li><strong>average worked time</strong> - you can select the project vs total time</li>
            <li><strong>worked time total</strong> - the sum of all worked hours </li>
            <li><strong>total worked hours per client/project per unit of time</strong> - same as above, but per project/client</li>
            <li><strong> worked hours per user per project </strong> - visually check how much each user contributed per project</li>
            <li><strong>average start time</strong> - when are your employees commencing work, per project and total </li>
          </ul>

          <h2 id="export" className="md:text-xl xl:text-2xl leading-none w-full font-extrabold text-blue-700 pt-15 md:pt-20 px-2">
            Extract data
          </h2>
          <p className="my-8 font-light">
            You can extract all data visible on screen by clicking on the Export and Export all
          </p>
          <div className="m-auto bg-gradient-to-r from-gray-500 to-blue-400">
            <img src={backendExport} alt="back-end export data"  className="md:p-10" />
          </div>
          <p className="my-8 font-light">
            You can extract all data visible on screen by clicking on the Export and Export all
          </p>

          <h2 id="projects" className="md:text-xl xl:text-2xl leading-none w-full font-extrabold text-blue-700 pt-15 md:pt-20 px-2">
            Manage projects and clients
          </h2>
          <p className="my-8 font-light">
            Punch-in has been conceived to allow for Clients to have multiple projects and for Projects to have multiple clients.
            Clients is the reference entity for the purposes of billing, whereas Projects are the operational center of activities.
            As for the other tables, a Client can be created, modified or deleted.
          </p>
          <div className="m-auto bg-gradient-to-r from-gray-500 to-blue-400">
            <img src={backendNewClient} alt="back-end create new client"  className="md:p-10" />
          </div>
          <p className="my-8 font-light">
            When creating a new Client, the following fields are relevant:
          </p>
          <ul className="list-disc pl-10 text-base">
            <li><strong>Name</strong>: your client's name</li>
            <li><strong>BillingCycle</strong>: relevant for billing purposes, can be weekly, fortnightly, monthly</li>
            <li><strong>BillingStartDay</strong>: this is the cut off date and is a number between 1-30.
              If the client requires the billing to happen at a certain date, this is the field to edit.
            </li>
            <li><strong>HoursWorkingDay</strong>: defines the max number of hours that the client defines for a working day</li>
          </ul>
          <p className="my-8 font-light">
            From the bottom right corner, new projects can be added to the client.
          </p>
          <p className="my-8 font-light">
            <strong>Projects</strong> can be created by clicking the<strong> "Add new Projects"</strong> button, where the following
              screen will appear:
          </p>
          <div className="m-auto bg-gradient-to-r from-gray-500 to-blue-400">
            <img src={backendNewProject} alt="back-end create new project" className="md:p-10" />
          </div>
          <p className="my-8 font-light">
            When creating a new Project, the following fields are relevant:
          </p>
          <ul className="list-disc pl-10 text-base">
            <li><strong>Name</strong>: your project's name</li>
            <li><strong>Budget</strong>: the estimated budget to accomplish the project</li>
          </ul>
          <p className="my-8 font-light">
            Each project can have a Project manager designated (add or remove from the Project_manager field below the Clients link). In order to add a project manager, you need to create their profile from the <AnchorLink className="whitespace-nowrap text-blue-700 hover:text-blue-200 tracking-normal" href="#users" title="Link to users management">Users</AnchorLink> table.
          </p>
        </div>
      </div>
    </section>
  </LayoutSecondary>
);

export default Success;
